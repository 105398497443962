import React from "react";
import PropTypes from "prop-types";

export default class SearchUI extends React.Component {
  static propTypes = {
    onToggleFilter: PropTypes.func,
    filter: PropTypes.array,
    catList: PropTypes.array,
  }

  static defaultProps = {
    onToggleFilter: {},
    filter: [],
    catList: [],
  }

  renderList() {
    return this.props.catList.map((cat) => {
      return (
        <li
          key={cat.id}
          className="list-5__item"
          onClick={() => this.props.onToggleFilter(cat.id)}
        >
          <i
            className={`
              list-5__checkbox
              list-5__checkbox--cat-${cat.id}
              ${this.props.filter.includes(cat.id) ? 'list-5__checkbox--active' : ''}`}
          />
          {cat.name}
          <small className="text-muted">&nbsp;({cat.length})</small>
        </li>
      )
    });
  }

  render() {
    return (
      <div>
        <h2 className="h5 heading-2 mb-4">
          <span className="heading-2__icon-2">
            <img src="/assets/img/decoration-1.png" alt=""/>
          </span>
          保育施設の絞り込み
        </h2>
        <ul className="list-5">{this.renderList()}</ul>
        <div className="text-center">
          <a
            className="col-12 col-sm-6 col-lg-12 btn btn-secondary btn-lg btn-round btn-shadow"
            href="/nurseries/select"
            >
              <i className="fa fa-arrow-circle-right" aria-hidden="true" />
              すべての保育施設一覧へ
            </a>
        </div>
      </div>
    )
  }
}
