import React from "react";
import PropTypes from "prop-types";

export default class Marker extends React.Component {
  static propTypes = {
    cat: PropTypes.number,
    children: PropTypes.array,
    infowindow: PropTypes.bool,
    onToggleInfoWindow: PropTypes.func,
  }

  static defaultProps = {
    cat: 1,
    children: ['No Content.'],
    infowindow: false,
    onToggleInfoWindow() {},
  }

  render() {
    return (
      <div className={`ms-map-marker ${this.props.infowindow ? 'ms-map-marker--active' : ''}`}>
        <i
          className={`ms-map-marker__pin ms-map-marker__pin--cat-${this.props.cat}`}
          onClick={this.props.onToggleInfoWindow}
        />
        <div className="ms-map-marker__infowindow">
          <i
            className="ms-map-marker__infowindow__close"
            onClick={this.props.onToggleInfoWindow}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}
