import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Map from "./component/map";
import SearchUI from "./component/search-ui";

class App extends React.Component {
  state = {
    googleMapOption: {
      bootstrapURLKeys: {key: 'AIzaSyAp_byrpG6AIdaImI_xVTEy3aRoJMutCxQ'},
      center: [26.2636038, 127.7853],
      zoom: 13,
    },
    markers: [],
    filter: [],
    catList: []
  }

  componentWillMount() {
    Promise.all([
      axios.get('/api/get_facilitys.json'),
      axios.get('/api/get-categories.json')
    ]).then((response) => {
      const [facilitys, categories] = [...response];

      this.setState({
        markers: facilitys.data.map((v) => {
          return {
            id:         v.id,
            cat:        v.cat,
            lat:        v.lat,
            lng:        v.lng,
            permalink:  v.permalink,
            name:       v.name,
            address:    v.address,
            tel:        v.tel,
            infowindow: false,
          }
        }),
        catList: categories.data.map((v) => {
          return {
            id: v.id,
            name: v.name,
            length: facilitys.data.filter((f) => f.cat === v.id ).length,
          }
        }),
        filter: categories.data.map((v) => v.id)
      });
    })
  }

  onToggleFilter(id) {
    if(this.state.filter.includes(id)) {
      this.setState({filter: this.state.filter.filter((v) => v != id) });
    } else {
      this.setState({filter: [id, ...this.state.filter] });
    }
  }

  onChengeMapCenter(lat, lng) {
    const option = this.state.googleMapOption;
    option.center = [lat, lng];
    this.setState({ googleMapOption: option });
  }

  onToggleInfoWindow(id) {
    this.setState({
      markers: this.state.markers.map((marker) => {
        marker.infowindow = marker.id == id ? !marker.infowindow : false;
        return marker;
      })
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-4">
            <Map
              {...this.state}
              onToggleInfoWindow={this.onToggleInfoWindow.bind(this)}
              onChengeMapCenter={this.onChengeMapCenter.bind(this)}
            />
            <p className="map-note">※複数の施設が同じ所在地で運営されている場合がありますので、ご留意ください。</p>
          </div>
          <div className="col-lg-4 flex-lg-first mb-4">
            <SearchUI
              onToggleFilter={this.onToggleFilter.bind(this)}
              filter={this.state.filter}
              catList={this.state.catList}
            />
          </div>
        </div>
      </div>
    )
  }
}

document.addEventListener("DOMContentLoaded", ()=> {
  ReactDOM.render(<App />, document.getElementById('js-map-search'));
});
