import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from 'google-map-react';
import Marker from './marker';

export default class Map extends React.Component {
  static propTypes = {
    markers: PropTypes.array,
    filter: PropTypes.array,
    googleMapOption: PropTypes.object,
    onToggleInfoWindow: PropTypes.func,
    onChengeMapCenter: PropTypes.func,
  }

  static defaultProps = {
    markers: [],
    filter: [],
    googleMapOption: {},
    onToggleInfoWindow() {},
    onChengeMapCenter() {},
  }

  renderMarkers() {
    const markers = this.props.markers.filter((marker) => {
      return this.props.filter.includes(marker.cat);
    }).sort((a, b) => {
      return b.lat - a.lat;
    });

    return markers.map((marker)=> {
      return (
        <Marker
          key={marker.id}
          lat={marker.lat}
          lng={marker.lng}
          cat={marker.cat}
          infowindow={marker.infowindow}
          onToggleInfoWindow={() => {
            this.props.onToggleInfoWindow(marker.id);
            this.props.onChengeMapCenter(marker.lat, marker.lng);
          }}
        >
          <a
            className="text-green font-weight-bold"
            href={marker.permalink}
            target="_blank"
          >
            {marker.name}
          </a>
          <br />
          {marker.address}<br />
          {(() => {
            return marker.tel ? <span>TEL: {marker.tel}</span> : null;
          })()}
        </Marker>
      );
    })
  }

  render() {
    return (
      <div className="ms-map-area">
        <GoogleMapReact {...this.props.googleMapOption}>
          {this.renderMarkers()}
        </GoogleMapReact>
        <img
          className="ms-map-area__decoration-1"
          src="/assets/img/icon-6@2x.png"
          style={{width: '100px'}}
          alt=""
        />
      </div>
    );
  }
}
